import React from "react"
import Layout from "../components/layout"

const NewsletterSuccess = () => (
    <Layout>
        <div>
            <h2><strong>You’re now Subscribed!</strong></h2>
            <h3>You will recive newsletters from time to time. Also, You will be the first to know about sales for my games!</h3>
        </div>
    </Layout>
)

export default NewsletterSuccess